


































import { defineComponent } from "@vue/composition-api";
import axios from "@/helpers/axios";
import moment from "moment";
import RenewRecordOrder from "./renew-record-order.vue";
import { Empty, List, Popup, PullRefresh } from "vant";
moment.locale("zh-cn");

interface Order {
  id: number;
  payTime: number;
  amount: number;
  count: number;
  userName: string;
  userPhone: string;
}

export default defineComponent({
  components: {
    [Empty.name]: Empty,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Popup.name]: Popup,
    RenewRecordOrder
  },
  data() {
    return {
      keywordRaw: "",
      keyword: "",
      page: -1,
      size: 10,
      list: [] as Order[],
      renewRecordOrderPopup: {
        show: false,
        payOrderId: 0
      },

      refreshing: false,
      finished: false,
      loading: false,
      empty: false
    };
  },
  methods: {
    search() {
      this.keyword = this.keywordRaw;
      this.onRefresh();
    },
    toDetail(order: { id: number }) {
      this.renewRecordOrderPopup.payOrderId = order.id;
      this.renewRecordOrderPopup.show = true;
    },
    onRefresh() {
      this.page = -1;
      window.scrollTo(0, 0);
      this.getData(true);
    },
    onLoad() {
      this.getData();
    },
    async getData(isRefresh = false) {
      const data = await axios
        .post("/api/manage/servicePlan/getRenewCabinetOrderList", {
          keyword: this.keyword,
          page: this.page + 1,
          size: this.size
        })
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!data) return;
      this.list = isRefresh ? data.list : this.list.concat(data.list);

      this.loading = false;
      this.refreshing = false;
      this.finished = data.list.length < this.size;
      this.page++;
    },
    formatTime(time: number) {
      return moment(time).format("lll");
    }
  }
});
