




































import { defineComponent } from "@vue/composition-api";
import { List, PullRefresh, Empty, Sticky, Icon, Button } from "vant";
import moment from "moment";
moment.locale("zh-cn");

export default defineComponent({
  props: {
    payOrderId: Number
  },
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Empty.name]: Empty,
    [Sticky.name]: Sticky,
    [Icon.name]: Icon,
    [Button.name]: Button
  },
  data() {
    return {
      cabinet: {},
      list: [],

      refreshing: false,
      loading: false,
      empty: false,
      finished: false
    };
  },
  watch: {
    payOrderId: "onIdChange"
  },
  methods: {
    onIdChange() {
      this.getData();
    },
    onRefresh() {
      this.refreshing = true;
      this.getData().finally(() => {
        this.refreshing = false;
      });
    },
    getData() {
      this.loading = true;
      return this.$axios
        .post("/api/manage/servicePlan/getRenewCabinetOrderDetail", { payOrderId: this.payOrderId })
        .then(res => {
          const data = res.data.data;
          this.list = data.list;
          this.empty = this.list.length == 0;
        })
        .catch(() => {
          this.empty = true;
        })
        .finally(() => {
          this.finished = true;
          this.loading = false;
        });
    },
    formatTime(time: Date) {
      return moment(time).format("lll");
    }
  }
});
